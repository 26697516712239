var prospectInstalled = false;

function isValidEmail(email) {
  var regex = /^([a-zA-Z0-9_'.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

function handleGetStartedFormSubmission() {
  var $getStartedForm = $('#start-prospecting-form');

  $getStartedForm.find('button').click(async function(e) {
    e.preventDefault();

    var $emailInput = $('#email-addy');
    var email = $emailInput.val();

    if (email) {
      email = email.trim().toLowerCase();
    }

    $emailInput.removeClass('invalid-email');

    $.ajax({
      data        : JSON.stringify({ email : email }),
      contentType : 'application/json',
      type        : 'post',
      url         : `${API_URL}/signup`,
    });

    if (isValidEmail(email)) {
      $getStartedForm.fadeOut('slow', function() {
        $('#email-sent').fadeIn('slow');
      });

      if (analytics) {
        const user = await getUser();

        if (user && user.id) {
          await analytics.identify(user.id);
        } else {
          await analytics.identify({
            email,
          });
        }

        analytics.track('website.demo_requested', {
          email,
        })
      }
    } else {
      $emailInput.addClass('invalid-email animated shake').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
        $emailInput.removeClass('animated shake');
      });
    }
  });
}

function showWelcomeBack() {
  $('#welcome-back').fadeIn(function() {

    setTimeout(function() {
      $('#welcome-back .support').fadeIn('slow');
    }, 1500);
  });
}

function showStartProspectingToday() {
  $('#book-a-demo').fadeIn();

  handleGetStartedFormSubmission();
}

function checkIfProspectInstalled() {
  if (prospectInstalled) {
    showWelcomeBack();
  } else {
    showStartProspectingToday();
  }
}

function receiveMessage() {
  if (event.data['prospect-extension-id'] || event.data.prospectInstalled) {
    prospectInstalled = true;

    showWelcomeBack();
  }
}

async function getUser() {
  if (!Model.userLoaded) {
    Model.user = await ProspectAPI.getUser();
    Model.userLoaded = true;
  }

  return Model.user;
}

window.addEventListener('message', receiveMessage, false);

$(function() {
  setTimeout(checkIfProspectInstalled, 1000);
});
